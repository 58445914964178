(function ($) {
    var className = 'btn';
    var defaultBtns = $('.' + className).not('input, .btn--hotspot');
    var hotspotBtns = $('.' + className + '--hotspot');
    let anim__default;
    let anim__hotspot;

    function default__btnEnter(span) {
        // if we have an active hover timeline we rewind it all the way before the next one starts
        if (anim__default && anim__default.isActive()) {
            anim__default.progress(0);
        }
        // create needed timeline for hover
        anim__default = gsap.timeline();
        anim__default.to(span, {
            duration: 0.3,
            width: '100%'
        });
    }

    function default__btnExit() {
        anim__default.reverse();
    }
 
    defaultBtns.each(function(i) {
        // add element to animate
        $(this).append('<span></span>');
        var span = $(this).find('span');
        gsap.set(span, {
            width: 0,
        });

        $(this).mouseenter(function() {
            default__btnEnter(span);
        })
        $(this).mouseleave(function() {
            default__btnExit();
        });
    })

    hotspotBtns.each(function(i) {
        // format the html
        var newHTML = '<span>' + $(this).text() + '</span>';
        var HTML = $(this).html().replace($(this).text(), newHTML);
        $(this).html(HTML);

        
    });

})(jQuery);