(function ($) {
    function initFilters() {
        var section = $('.filter');
        section.each(function(i, element) {
            var $this = $(element),
            nav = $this.find('.filter__nav'),
            content = $this.find('.filter__content'),
            items = $this.find('[data-filter]'),
            categories = [];

            // build nav
            items.each(function(i, element) {
                var data = $(element).attr('data-filter');
                if (categories.indexOf(data) === -1) categories.push(data);
            });

            $.each(categories, function( index, value ) {
                
                var inputHTML = '<input type="radio" id="' + value +'" name="filter"/>',
                labelHTML = '<label for="' + value +'">' + value.split('-').join(' ') +'</label>',
                clearHTML = '<a>Clear all</a>';
                if(index === 0) {
                    nav.append('<div class="filter__categories"></div><div class="filter__clear">' + clearHTML + '</div>');
                }
                nav.find('.filter__categories').prepend('<div>' + inputHTML + labelHTML + '</div>');
            });

            nav.on('click', '.filter__clear a', function(e) {
                e.preventDefault();
                if (section.find('input[name="filter"]:checked').length) {
                    section.find('input[name="filter"]:checked')[0].checked = false;
                }
                
                items.show();
                section.find('.masonry__grid').masonry();
            });

            nav.on('change', 'input[name="filter"]', function(){
                var category = $(this).attr('id');
                content.find('[data-filter]').hide()
                content.find('[data-filter="' + category + '"]').show()
                // init masonary
                section.find('.masonry__grid').masonry({columnWidth: '[data-filter="' + category + '"]'});
            });
        })
    }

    initFilters();




})(jQuery);