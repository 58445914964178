(function ($) {
    var className = '.accordion';
    var section = $(className);
    if(section.length) {

        var checkbox = section.find('input[type="checkbox"]');

        checkbox.on('change',function(){
            var $this =  $(this),
                id = $this.attr('id'),
                checked = $this.prop('checked'),
                section = $this.closest(className),
                inputs = section.find('input[type="checkbox"]').not('#' + id);

            if(checked) inputs.prop( "checked", false);  
        });
    }
})(jQuery);