(function ($) {
    var checkbox = $('#footer_toggle');
    var footer = $('.footer');
    var toggle = footer.find('[for="footer_toggle"');
    var container = footer.find('.footer__container');
    
    function setFooterHeight() {
        var 
            height = container.outerHeight(),
            offset = $('.header').outerHeight() + footer.find('.footer__toggle').outerHeight();
        // set new height
        if (height > window.innerHeight - offset) height = window.innerHeight - offset;
        footer.height(height)
    }

    function anim() {
        var tl = gsap.timeline({
            paused: true,
            onStart: function(){ toggle.addClass('active') },
            onReverseComplete: function(){ toggle.removeClass('active') },
        });

        gsap.set(footer, {yPercent: -0});

        tl.to(footer, {yPercent: -100});

        if(checkbox.length) {
            checkbox[0].animation = tl;
        }
    }

    if(footer.length) {
        setFooterHeight();
        anim();
        $(window).on('resize', function() { setFooterHeight(); });
    }
    
    checkbox.on('change',function(){
        var checked = $(this).prop('checked');
        checked ? this.animation.play(): this.animation.reverse();
    });
})(jQuery);