(function ($) {
    var className = '.event-day',
        section = $(className);

    function showContent(ele) {
        var tl = gsap.timeline();
            tl.set(ele, {opacity: 0, visibility: 'visible'})
            tl.to(ele, {opacity: 1});
        return tl;
    }

    if (section.length) {
        var content = section.find('[data-hide]');
        var video1 = section.find(className + '__bg video:first-child');
        var video2 = video1.next('video');
        var svg = section.find('svg g');
        var svgContent = section.find(className + '__content-group');
        var tl = gsap.timeline();
        
        if(video1.length) {
            $(window).on('load', function() {
                if(window.location.hash == '') {
                    video1[0].play();
                    video1.on('ended', function() {
                        tl.add( showContent(content) )
                        if(video2.length) {
                            tl.to(video1, {opacity: 0});
                            tl.set(video1, {visibility: 'hidden'});
                            tl.add(function() {
                                video2[0].play()
                            })
                        }
                    });
                } else {
                    tl.add( showContent(content) )
                    if(video2.length) {
                        tl.to(video1, {opacity: 0});
                        tl.set(video1, {visibility: 'hidden'});
                        tl.add(function() {
                            video2[0].play()
                        })
                    }
                    if($(window.location.hash).length && $(window.location.hash)[0].animation) {
                        $(window.location.hash)[0].animation.play()
                    }
                }
                
            });
        }

         // set titles and buttons to pionts on svg 
        if(svg.length && svgContent.length) {
            $(window).on('load resize', function() {
                svg.each(function(i){
                    var btnPos = $(this).find('.group__btn')
                    var btn = $(svgContent[i]).find('button');
                    if(btn.length) {
                        var btnOffset = btnPos[0].getBBox();
                        gsap.set(btn, {position: 'absolute', y: btnOffset.y, x: btnOffset.x});
                    }
                    
                })
            });
            

        }

        // hotspot on hover 
        svg.each(function(i) {
            var $this = $(this);
            var children = $this.find('path, .btn');
            children.each(function(i) {
                $(this).mouseenter(function() {
                    $this.find('foreignObject').children().addClass('active');
                    $this.find('path').addClass('active');
                })
                $(this).mouseleave(function() {
                    $this.find('foreignObject').children().removeClass('active');
                    $this.find('path').removeClass('active');
                });
            });
        })

        svg.children().on('click', function() {
            var $this = $(this),
                link = '#'+ $this.parent('g').attr('data-link');

            if($(link).length && $(link)[0].animation) {
                $(link)[0].animation.play();
            }

        });

        // handle the preview-menu
        svg.find('.group__btn, .group__path').on('click', function() {
            var $this = $(this),
            link = '#'+ $this.closest('g').attr('data-link'),
            groupEle = $this.closest('[data-link]');

            if($(link).hasClass('preview-menu')) {
                groupEle.toggleClass('active');
            }
        });

        // handle the hash
        if(window.location.hash) {
            console.log('hash')
        }



    }

})(jQuery);