(function ($) {
    var section = $('.preview');
    if(section.length) {
        section.each(function(i, ele) {
            var $this = $(ele),
                inner = $this.find('.preview__inner'),
                close = $this.find('.preview__close'),
                id = $this.attr('id');

            gsap.set(ele, { display: 'none', opacity: 0});
            gsap.set(inner, { xPercent: -100});

            var tl = gsap.timeline({ 
                paused:true,
                onStart: function() { window.location.hash = id; },
                onReverseComplete: function() { window.location.hash = ''; }
            });
                tl.set('html', { overflow: 'hidden' })
                tl.set(ele, { display: 'block' })
                tl.to(ele, { opacity: 1 }, 0)
                tl.to(inner, { xPercent: 0 }, 0)

                ele.animation = tl;

            // close the animation
            close.on('click', function(e) {
                e.preventDefault();
                ele.animation.reverse();
            });
        });
    }

    // or mini menu
    var miniMenu = $('.preview-menu');
    if(miniMenu.length) {
        miniMenu.each(function(i, ele) {
            var $this = $(ele),
                id = $this.attr('id'),
                html = $this.html(),
                svgGroup = $('.event-day__bg').find('svg [data-link="'+ id +'"]');

            // inject html into svg
            svgGroup.find('.group__menu').append(html)
            

        });
    }

})(jQuery);