import './components/_accordion.js';
import './components/_buttons.js';
import './components/_drop-menu.js';
import './components/_feedback.js';
import './components/_filter.js';
import './components/_footer.js';
import './components/_go-back.js';
import './components/_login.js';
import './components/_menu.js';
import './components/_notification.js';
import './components/_pledge.js';
import './components/_preview.js';
import './components/_video-template.js';
import './components/_videos.js';

