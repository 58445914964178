(function ($) {
    var name = 'notification',
        className = '.notification',
        container = $(className),
        delay = 1,
        value = 1,
        options = {
            expires: 365,
            path: '/',
            domain: window.location.hostname,
        },
        tl = gsap.timeline({reversed: true, paused: true});

        if(container.length){
            tl.set(container, { display: 'none', opacity: 0, yPercent: 100})
            tl.to(container, { display: 'block'})
            tl.to(container, { opacity: 1, yPercent: 0})
        }

    $(window).on('load', function() {
        if (Cookies.get(name) == undefined) {
            setTimeout(function() {
                // show cookie popup
                tl.play();
            }, delay*1000);
        }
    });

    container.on('click', className+'__close', function(e) {
        e.preventDefault();
        Cookies.set(name, value, options);
        // show cookie popup
        tl.reverse();
    });
})(jQuery);