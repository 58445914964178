(function ($) {
    var className = 'drop-menu';
    var ele = $('.' + className);
    var children = ele.children();
    let anim;

    function itemEnter(ul) {
        var siblingBtn = ul.siblings('.btn')
        // if we have an active hover timeline we rewind it all the way before the next one starts
        if (anim && anim.isActive()) {
            anim.progress(0);
        }

        // create needed timeline for hover
        anim = gsap.timeline({
            onComplete: function(){ siblingBtn.addClass('active') },
            onReverseComplete: function(){ siblingBtn.removeClass('active') },
        });
        anim.to(ul, {
            duration: 0.3,
            clipPath: 'polygon(0 0, 100% 0, 100% 100%, 0% 100%)'
        });
    }
    function itemExit() {
        anim.reverse();
    }

    children.each(function(i) {
        var ul = $(this).find('ul');
        gsap.set(ul, { clipPath: 'polygon(0 0, 100% 0, 100% 0, 0 0)' });

        $(this).mouseenter(function() {
            itemEnter(ul);
        })
        $(this).mouseleave(function() {
            itemExit();
        });
    });
    


})(jQuery);